<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-1"></div>
            <div class="row">
              <div class="col-12">
                <ul role="tablist" class="nav nav-tabs">
                  <li role="presentation" class="nav-item">
                    <router-link :to="{ name: 'email-templates' }" class="nav-link active">Шаблоны</router-link>
                  </li>
                  <li role="presentation" class="nav-item">
                    <router-link :to="{name: 'mailing-list'}" class="nav-link">Рассылка</router-link>
                  </li>
                </ul>
              </div>

            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ templates.from }} по {{ templates.to }} из {{ templates.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="templates.total"
                      :per-page="templates.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Дата</th>
                  <th>Название</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="template in templates.data" :key="template.id">
                  <td class="font-weight-bold">#{{ template.id }}</td>
                  <td>{{ template.created_at | formattedDate }}</td>
                  <td>{{ template.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ templates.from }} по {{ templates.to }} из {{ templates.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="templates.total"
                      :per-page="templates.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BPagination,
} from 'bootstrap-vue';

export default {
  name: 'Templates',
  components: {
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      templates: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
      },
    };
  },
  watch: {
    async currentPage(newValue) {
      this.templates.current_page = newValue;
      await this.getTemplates();
    },
  },
  async mounted() {
    await this.getTemplates();
  },
  methods: {
    async getTemplates() {
      this.templates = (await this.$api.templates.list({
        ...(this.templates.current_page > 1 && { page: this.templates.current_page }),
        limit: 25,
      })).data;
    },
  },
};
</script>

<style scoped>

</style>
